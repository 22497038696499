import Config from "../Config";
import { IAuthGame } from "../Interfaces/IAuthGame";
import { IAuthUser } from "../Interfaces/IAuthUser";
import { GetStoredToken } from "./UserService";

export interface IGameUsers {
  game: IAuthGame;
  users: IAuthUser[];
}

export const GetUsersByGameAppId = async (
  id: number
): Promise<IGameUsers | undefined> => {
    const token = GetStoredToken()[1];
  if (!token) return;
  const req = await fetch(`${Config.baseURL}/steam/game/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (req.status === 200) {
    return await req.json();
  }
};
