import React, { useState } from 'react';
import Config from '../../Config';
import logo from '../../Images/logo.png';

const orgas = [
    {
        username: "TheGetGet",
        img: "https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/avatars/9e/9e58625c884ee8fd3a9a388521620b1194c8e51b_full.jpg"
    },
    {
        username: "Lafidhana",
        img: "https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg"
    },
    {
        username: "Aethys",
        img: "https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/avatars/65/656d9249f82bd7136ab9d89f2c78f6b9650e2527_full.jpgv"
    },
    {
        username: "Eglantine",
        img: "https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/avatars/9e/9ebdb2b6b618bd39c0fb9991050fadfbe70e4d58_full.jpg"
    },
    {
        username: "Quato",
        img: "https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/avatars/98/98b758860a473b0b49a9ae6551113666de4897b6_full.jpg"
    },
    {
        username: "CraZyBoj",
        img: "https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/avatars/d7/d7b8f6da54604eda14dfbeef521659ff1fb1ba4d_full.jpg"
    },
]

const Home = () => {
    return (
    <div className="container mx-auto mt-5">
        <header className="sm:flex items-center sm:justify-between">
            <div className="flex items-center">
                <img src={logo}/>
                <div>
                    <h1 className="font-bold text-xl text-blue-800">{Config.sitename}</h1>
                    <h2 className="text-gray-600">{Config.subname}</h2>
                </div>
            </div>
            <nav>
                <ul className="flex sm:space-x-10 items-center justify-evenly">
                    <li><a href="#contact">Nous contacter</a></li>
                    <li>
                        <a href="/login">Connexion</a>
                    </li>
                    <li>
                        <a href="/register" className="py-2 px-4 rounded text-white font-semibold bg-gradient-to-r from-blue-400 to-blue-500">Créer un compte</a>
                    </li>
                </ul>
            </nav>
        </header>

        <main>
            <h1 className="text-2xl font-bold mt-10">Qui sommes-nous ?</h1>
            <section className="flex mx-auto mb-10 bg-white shadow rounded p-10 tracking-wide">
                Bienvenue sur le nouveau site internet de {Config.sitename}, tout n'est pas encore terminé mais c'est en bonne voie.<br></br>
                Vous pouvez utiliser le site comme avant, même s'il va falloir chercher un peu pour trouver ce que vous aviez 😋
            </section>
            <h1 className="text-2xl font-bold mt-10">Les organisateurs</h1>
            <section className="flex justify-evenly mx-auto mb-10">
                {orgas.map(o => (
                    <div key={o.img} className="h-20 sm:h-52 items-center flex flex-col pt-4 relative">
                        <img className="h-3/4 rounded-lg shadow-xl" src={o.img} />
                        <span className="font-semibold">{o.username}</span>
                    </div>   
                ))}
            </section>
            <h1 id="contact" className="text-2xl font-bold mt-10">Nous contacter</h1>
            <section className="flex  flex-col mx-auto mb-10 bg-white shadow rounded p-10">
                <label className="block">
                    <span className="text-gray-700">Votre nom</span>
                    <input type="text"
                    className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
                    />
                </label>
                <label className="block">
                    <span className="text-gray-700">Votre adresse mail</span>
                    <input type="email"
                    className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
                    />
                </label>
                <label className="block">
                    <span className="text-gray-700">Votre message</span>
                    <textarea
                    className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
                    rows={4}
                    ></textarea>
                </label>
                <button className="bg-blue-400 py-2 px-4 rounded shadow block my-4 self-end text-white font-bold">Envoyer</button>
            </section>
        </main>

        <div className="w-full text-center mb-4 text-gray-500">© 2021 LaFrancis</div>
    </div>
    )
}

export default Home;